const awsmobile = {
  aws_project_region: 'eu-west-2',
  aws_cognito_region: 'eu-west-2',
  aws_user_pools_id: 'eu-west-2_hTgFQTWc0',
  aws_user_pools_web_client_id: '196548q4e4boq038msekdn7c5r',
  oauth: {},
  aws_appsync_graphqlEndpoint: 'https://mrvyyvsecvhmhphuq25ffjm6qy.appsync-api.eu-west-2.amazonaws.com/graphql',
  aws_appsync_region: 'eu-west-2',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS'
};

export default awsmobile;
