import { Flex, Text } from "@engaging-tech/components"
import { Link } from "@engaging-tech/routing"
import React from "react"

const CMI = () => (
  <Flex flexDirection="column">
    <Flex justifyContent="center" mb={4} mt={4}>
      <img
        src="https://media.engaging.works/production/media/companylogos/CMI.png"
        textAlign="center"
        width="300px"
        alt=""
      />
    </Flex>
    <Flex flexDirection="column" p={[1, 0]}>
      <Text fontFamily="Tiempos" textAlign="center" fontSize={5} color="light.0" pb={4}>
        Choose a survey tailored towards your working environment
      </Text>
      <Text fontFamily="Tiempos" textAlign="center" fontSize={4} color="light.0" pb={2}>
        Which of the following best reflects your working situation?
      </Text>
    </Flex>
    <Flex width={1 / 1} flexDirection={["column", "row"]} justifyContent="center" mt={3}>
      <Link external newTab to="https://app.workl.com/organisationsurvey/5f1190e8d95fbbe074f1b870">
        <Flex
          flexDirection="column"
          alignItem="center"
          bg="secondary.0"
          borderRadius={6}
          width={["90vw", "230px"]}
          height="105px"
          m={3}
          py={4}
          justifyContent="center"
        >
          <Text textAlign="center">I am currently a home worker</Text>{" "}
        </Flex>
      </Link>
      <Link to="https://app.workl.com/organisationsurvey/5f1056ead95fbbe074ec2d9f">
        <Flex
          flexDirection="column"
          alignItem="center"
          bg="secondary.0"
          borderRadius={6}
          width={["90vw", "230px"]}
          height="105px"
          m={3}
          py={4}
          justifyContent="center"
        >
          <Text textAlign="center">I am currently working in my workplace</Text>
        </Flex>
      </Link>
    </Flex>
  </Flex>
)

export default CMI
