const RetailTrustActionPlanItems = [
  {
    section: "reward_recognition",
    heading: "Reward and Recognition",
    icon: "trophy",
    paragraph:
      "Recognition can be a more powerful ongoing motivator than pay. If you have a low score in this area, find out how to improve it by clicking the button below.",
    modalInfo: [
      {
        question: "Do you feel appropriately rewarded for your work?",
        explanation:
          "It is not unusual to find people who say they are not paid enough. Why wouldn’t they! Pay, however, can only minimise discontent rather than motivating. Let me explain. If somebody feels they are 5% underpaid it niggles away at them, but if they feel they are paid 5% more than they are worth, they don’t work any harder. The first step is to reassure yourself that you are being paid fairly and then see what you can do to improve your pay. If you score poorly on this question you should consider doing the following:",
        suggestedActions: [
          {
            heading: "Suggested Actions:",
            actions: [
              {
                text: "Understand the pay range for the job/jobs in question by discussing this with your manager. (Alternatively, you can look at external @LINK@pay benchmarking information@LINK@.)",
                children: [],
                placeholder: {
                  key: "@LINK@",
                  url: "https://www.payscale.com/index/UK"
                }
              },
              {
                text: "Discuss with your manager where you sit in the range and why. Don’t be angry or disagree, just ask questions to better understand how your pay is set.",
                children: []
              },
              {
                text: "Set out a plan, with your manager’s support, to improve your contribution and as a result your earning potential.",
                children: []
              },
              // {
              //   text:
              //     "Consider what opportunities exist for you to be paid an additional bonus for work and projects completed to a high standard.",
              //   children: []
              // },
              {
                text: "Are there more responsibilities you can take in order to increase your earning power?",
                children: []
              },
              {
                text: "Can you learn new skills or build your knowledge to earn more?",
                children: []
              },
              {
                text: "If your current job will not bring you the pay levels you want, what added skills and abilities will be required for promotion?",
                children: []
              }
            ]
          }
        ]
      },
      {
        question: "Are you happy with your working hours?",
        explanation:
          "If you score poorly on this question there could be a number of reasons. You should therefore ask yourself the following:",
        explanationChildren: [
          "1. Am I unhappy because I am working too many hours?",
          "2. Am I unhappy because I am working too few hours?",
          "3. Am I unhappy because I would like more flexibility in my working hours?",
          "4. Am I unhappy because I don’t know what my working hours are/will be?",
          "5. Am I unhappy about my working hours because of my pay? (For instance too many hours for the pay you receive or not enough hours for the pay desired.)"
        ],
        suggestedActions: [
          {
            heading: "Suggested Actions:",
            actions: [
              {
                text: "Which of the reasons above is making feel unhappy about your working hours?",
                children: []
              },
              {
                text: "If number 1, consider how you might contain workload within the working week. Agree with your manager and colleagues that you will answer emails between 8am to 6pm on working days but not after that or at the weekend.",
                children: []
              },
              {
                text: "If number 2, discuss with your manager what scope exists to do more hours. Can you work in another department too? Is there more training you could do to take on more tasks?",
                children: []
              },
              {
                text: "If number 3, discuss with your manager how you might  work more flexibly. How might that help the organisation as well as you?",
                children: []
              }
              // {
              //   text:
              //     "If number 4 - try to understand from your manager the challenges they have with scheduling. Explain to your manager the challenges you have from not knowing your working hours. Try to find a mutually helpful plan.",
              //   children: []
              // }
            ]
          }
        ]
      },
      {
        question: "Do you feel recognised when you do something well?",
        explanation:
          "Recognition is one of the most powerful motivators and causes of high levels of workplace engagement. Yet on average people are only thanked for what they do once every four and a half months, whereas they are criticised twice a week. Praise creates a positive working environment. If you score poorly on this question you can do the following:",
        suggestedActions: [
          {
            heading: "Suggested Actions:",
            actions: [
              {
                text: "Praise your manager and colleagues when they do something well. Hopefully your lead might catch on!",
                children: []
              },
              {
                text: "If you manage people you might consider the points below, and also suggest them to your manager.",
                children: []
              },
              {
                text: "Recognise and praise somebody when they do something well.",
                children: []
              },
              {
                text: "If somebody completes work you think they can improve upon use it as an opportunity to coach (i.e ask: ‘how well did you feel that went’, ‘could you have done that differently’, or ‘what more support might you need if you were to do that task again’ etc) so that the individual feels that they are learning rather than being criticised.",
                children: []
              },
              {
                text: "Send somebody a note/email/card when they do something well.",
                children: []
              },
              // {
              //   text:
              //     "Give somebody a small gift (chocolate/flowers/wine) when they do something that has impressed you.",
              //   children: []
              // },
              {
                text: "Recognise individual successes publicly and discuss failures privately.",
                children: []
              },
              {
                text: "For outstanding work, recognise an individual with an email to the CEO/Chairperson to let them know the outstanding work that’s been achieved and by who. For outstanding work, you can suggest applying for an industry award.",
                children: []
              },
              {
                text: "Take every opportunity to recognise and celebrate your team or a member of your team’s success.",
                children: []
              },
              {
                text: "Ask your boss to recognise individuals in your team who have done well.",
                children: []
              }
            ]
          }
        ]
      }
    ],
    supportiveMaterials: [
      {
        type: "Article",
        title: "The six traits all confident people share",
        url: "https://www.retailtrust.org.uk/bring-your-best-self-to-work/the-six-traits-all-confident-people-share/1191.article?rwp_campaign=HA2&rwp_medium=HappinessAssessment",
        author: "Retail Trust"
      },
      {
        type: "Article",
        title: "Feeling overlooked? How to get noticed at work",
        url: "https://www.retailtrust.org.uk/bring-your-best-self-to-work/eight-ways-to-get-noticed-at-work/1189.article?rwp_campaign=HA2&rwp_medium=HappinessAssessment",
        author: "Retail Trust"
      },
      {
        type: "Article",
        title: "Hayley Mulender calls time on imposter syndrome",
        url: "https://www.retailtrust.org.uk/together-fest/you-should-go-and-love-yourself-hayley-mulenda-calls-time-on-imposter-syndrome/1491.article?rwp_campaign=HA2&rwp_medium=HappinessAssessment",
        author: "Retail Trust"
      },
      {
        type: "Article",
        title: "Jen Sincero You are a badass: 15 minutes to shift your mindset",
        url: "https://www.retailtrust.org.uk/together-fest/you-are-a-badass-15-minutes-to-shift-your-mindset/1482.article?rwp_campaign=HA2&rwp_medium=HappinessAssessment",
        author: "Retail Trust"
      },
      {
        type: "Article",
        title: " WorkL - How to get the recognition you deserve",
        url: "https://app.workl.com/business-library/browse/articles/5d750d53c289ff00166b408c",
        author: "Workl"
      }
    ],
    furtherReading: []
  },

  {
    section: "information_sharing",
    heading: "Information Sharing",
    icon: "information",
    paragraph:
      "There is often a disconnect (real or perceived) between the information your managers are providing and what you are receiving. Read the action plan to help you to identify opportunities to improve.",
    modalInfo: [
      {
        question: "Do you have enough information to do your job well?",
        explanation:
          "This question goes to the heart of whether you feel you have been properly trained to do your job well or have enough information on an ongoing basis to do what is required. If you score poorly on this question you should consider doing the following:",
        suggestedActions: [
          {
            heading: "Suggested Actions:",
            actions: [
              {
                text: "Review whether you have been trained effectively to complete your tasks. If not, you should speak to your manager and discuss what’s missing from your training plan.",
                children: []
              },
              {
                text: "Is information readily at hand for you to complete your job efficiently? If not, you should speak to your manager about what you feel is helpful information that’s perhaps come too late, or you didn’t receive. Talk also about how it will help you do a better job, and ask questions to check they understand your point of view.",
                children: []
              },
              {
                text: "Is new information relevant to your job effectively communicated? If not, consider how it might be and discuss this with your manager it can be helpful to consider whether the time of the information, the clarity of message or forum of where communication happens are factors in this.",
                children: []
              },
              {
                text: "Do you have regular team meetings to share updates. Regular team meetings with agendas are a good, stable format that enable information sharing. Regular and more informal ‘stand-up’s can also be a good way to information share across a team and enable information to flow more. Talk about this with your line manager and let them know that you would find this helpful if they don’t already happen.",
                children: []
              }
            ]
          }
        ]
      },
      {
        question: "Do you feel information is openly shared with you at work?",
        explanation:
          "This question is about whether you understand how the organisation is performing and your role in the organisational outcomes. This question is also exploring whether your manager is being transparent with information. If you score poorly on this question you should consider doing the following: ",
        suggestedActions: [
          {
            heading: "Suggested Actions:",
            actions: [
              {
                text: "Are there routine meetings to share how the organisation is performing and its plans? If so, do you attend? If not, how might you ask for this information?",
                children: []
              },
              {
                text: "Does the organisation give routine updates online or through reports? Can you see these?",
                children: []
              },
              {
                text: "Are you taking every opportunity to ask questions and feedback about the organisation’s and your department’s performance",
                children: []
              },
              {
                text: "Does the organisation have a newsletter or communication portal to keep employees up to date? Do you keep up to date with this?",
                children: []
              }
            ]
          }
        ]
      },
      {
        question: "Do you feel your views are heard at work?",
        explanation:
          "If you do not feel your views are listened to then it is unlikely you will feel empowered or engaged to make a full contribution to the organisation. If you score poorly on this question you should consider doing the following:",
        suggestedActions: [
          {
            heading: "Suggested Actions:",
            actions: [
              {
                text: "Ask yourself what you are doing to give feedback to your manager and colleagues. Is it constructive? Positivity intended?",
                children: []
              },
              {
                text: "Are you listening as much as giving your views?",
                children: []
              },
              {
                text: "Try an approach where you and ask for five bits of information before you give one piece of advice or direction.",
                children: []
              },
              {
                text: "Are there good suggestions/ideas schemes or formal feedback sessions you can contribute to?",
                children: []
              },
              {
                text: "Remember everyone has a different viewpoint because everyone has different experiences, education and upbringing. None are right or wrong they are just what people think based on those factors. All contributions should be welcomed to bring diversity of view, collective agreement and understanding. Try to understand why others have the views they have before you jump in to change them!",
                children: []
              }
            ]
          }
        ]
      }
    ],
    supportiveMaterials: [
      {
        type: "Article",
        title: "8 Questions you should be asking your boss",
        url: "https://app.workl.com/business-library/browse/articles/5d76aa36c289ff00166b4179",
        author: "WorkL"
      },
      {
        type: "Article",
        title: " Is it ever OK to go over your boss's head",
        url: "https://www.retailtrust.org.uk/bring-your-best-self-to-work/is-it-ever-ok-to-go-over-your-bosss-head/1190.article?rwp_campaign=HA2&rwp_medium=HappinessAssessment",
        author: "Retail Trust"
      },
      {
        type: "Article",
        title: "Questions you should be asking your boss",
        url: "https://app.workl.com/business-library/browse/articles/5d7a5b22c289ff00166b41f2",
        author: "WorkL"
      }
    ],
    furtherReading: []
  },

  {
    section: "empowerment",
    heading: "Empowerment",
    icon: "person",
    paragraph:
      "Your empowerment is influenced by both you and those around you. Answering the questions in the Action Plan will help you understand your potential areas to focus on.",
    modalInfo: [
      {
        question: "Are you empowered to make decisions?",
        explanation:
          "When you own something you care more. The secret of empowerment is that you feel you own things, like decisions, projects, action plans. As a result of ownership you feel more accountable and care more about the outcomes. It is also about whether you have the freedom to do what you need to do, to be effective in your job, making the organisation more effective and increasing your earning potential. Empowerment requires your manager to delegate and delegation requires training and coaching. But when done effectively the whole organisation moves more quickly. If you score poorly on this question you should consider doing the following:",
        suggestedActions: [
          {
            heading: "Suggested Actions:",
            actions: [
              {
                text: "Are you clear on the freedoms you have and the flexibility you can exercise in your job? If you’re not, ask your manager to be clearer, and talk through an example to bring this to life for them.",
                children: []
              },
              {
                text: "Do you feel your manager and the organisation set the right parameters for you to make decisions? If not discuss with your manager what more you’d like and why this will benefit everyone.",
                children: []
              },
              {
                text: "Do you have the right level of training and experience for the delegations you have? If not, discuss with your manager what more you need, and try to agree a plan for the next few months. Write down your action plan, and ask your manager to support you by providing time to achieve these actions.",
                children: []
              },
              {
                text: "When things go wrong, and they will, admit it and discuss with your manager what you have learned and if more training or coaching is required.",
                children: []
              }
            ]
          }
        ]
      },
      {
        question: "Do you have the resources you need to do your job well?",
        explanation:
          "In any business resources are tightly managed. Too much resource means that the business will not make as much profit or be as efficient as it might be. Too little resource places considerable strain on you and the team. It’s a fine balance. If you score poorly on this question you should consider doing the following:",
        suggestedActions: [
          {
            heading: "Suggested Actions:",
            actions: [
              {
                text: "Consider what more you need to do your job well:",
                children: [
                  "More people",
                  "More hours",
                  "Less work/more realistic timeframes",
                  "Better equipment",
                  "Better IT"
                ]
              },
              {
                text: "Talk to your manager about how improving any of the above can improve performance.",
                children: []
              },
              {
                text: "Consider how you might work more efficiently to relieve the pressure on hours and people.",
                children: []
              },
              {
                text: "Consider with the team how you might be able to invest more in resources through generating more income and/or efficiency.",
                children: []
              },
              {
                text: "Do you understand the economics of the organisation and any constraints there may be at this time on additional resources?",
                children: []
              }
            ]
          }
        ]
      },
      {
        question: "Do you feel trusted to make decisions?",
        explanation:
          "This question is about whether you feel trusted to operate within parameters set by the organisation and your manager. If you score poorly on this question you should consider doing the following:",
        suggestedActions: [
          {
            heading: "Suggested Actions:",
            actions: [
              {
                text: "Do you clearly understand what you can make decisions about? If not, you should ask your manager to clarify.",
                children: []
              },
              {
                text: "Is your manager over-controlling and asking to be consulted about decisions which they have said are delegated to you? If so, reconfirm with your manager what the boundaries are, and seek to understand why they want to be consulted.",
                children: []
              },
              {
                text: "Do you feel confident that you can make the right decision within the parameters set? If not, ask your manager for more training and coaching.",
                children: []
              }
            ]
          }
        ]
      }
    ],
    supportiveMaterials: [
      {
        type: "Article",
        title: "How to take charge of your life",
        url: "https://www.retailtrust.org.uk/how-to-kick-start-a-healthier-autumn/how-to-take-charge-of-your-life-simple-steps-to-being-your-own-main-character/1178.article?rwp_campaign=HA2&rwp_medium=HappinessAssessment",
        author: "Retail Trust"
      },
      {
        type: "Article",
        title: "Feel like you're in a career rut? Ask yourself these 5 questions",
        url: "https://www.retailtrust.org.uk/bring-your-best-self-to-work/five-steps-to-help-you-crush-your-career-rut/1186.article?rwp_campaign=HA2&rwp_medium=HappinessAssessment",
        author: "Retail Trust"
      },
      {
        type: "Article",
        title: "3 simple strategies to empowering yourself at work",
        url: "https://app.workl.com/business-library/browse/articles/5d7a67f8c289ff00166b425c",
        author: "WorkL"
      },
      {
        type: "Article",
        title: "Managers must delegate effectively to develop employees",
        url: "https://app.workl.com/business-library/browse/articles/5d890bfcc289ff00166b444d",
        author: "WorkL"
      }
    ],
    furtherReading: []
  },

  {
    section: "well_being",
    heading: "Wellbeing",
    icon: "heart",
    paragraph:
      "Healthy employees are more committed, and committed employees are healthier. Find out how to improve your score with our wellbeing action plan.",
    modalInfo: [
      {
        question: "Do you feel the organisation cares for your wellbeing?",
        explanation:
          "If you score poorly on this question, you’ll need to consider whether your concerns are about the organisation in general or the way you are being managed. You can look at scores about management relationships in the Job Satisfaction Action Plan.",
        suggestedActions: [
          {
            heading:
              "If you have a poor score as a result of the organisation not caring, consider the following steps to improve your wellbeing:",
            actions: [
              {
                text: "Are your working hours clearly defined with an understanding of the split between work-time and home-time?",
                children: []
              },
              {
                text: "Are your working hours causing pressure and stress?",
                children: []
              },
              {
                text: "Do you feel equipped for your job - sufficient training, resources, support, ability?",
                children: []
              },
              {
                text: "Can you suggest ideas to your manager to help you and your colleagues be healthier (e.g. gym memberships, classes etc)?",
                children: []
              },
              {
                text: "Can you suggest things to help the organisation promote a healthy lifestyle (e.g. free fruit, encouraging lunchtime walking etc)?",
                children: []
              }
            ]
          },
          {
            heading:
              "If you have a poor score as a result of feeling that your manager doesn’t care, you might consider the following steps:",
            actions: [
              {
                text: "Try to speak to your manager each day. Ask how they are each day - you’ll be surprised how well this works!",
                children: []
              },
              {
                text: "Show concern when they are unwell.",
                children: []
              },
              {
                text: "Wish them a happy break/holiday.",
                children: []
              },
              {
                text: "Take a genuine interest in them and their personal lives.",
                children: []
              },
              {
                text: "All the above actions are suggested as a way to improve the environment and relationship with your manager. If taking the lead on these things doesn’t improve your relationship with your manager, seek support from HR to support you in sharing how you feel with your manager. You may wish to consider another role within the organisation. In some instances, you may wish to consider a role in a new organisation. You can use the @LINK@WorkL Jobs Marketplace@LINK@ - it shows the happier industries to work in and companies to work for.",
                children: [],
                placeholder: {
                  key: "@LINK@",
                  url: "https://app.workl.com/find-work/jobs"
                }
              }
            ]
          }
        ]
      },
      {
        question: "Are you happy with your working environment?",
        explanation:
          "There are a number of things that may lead people to express unhappiness over their working environment. It could be their journey to work, the office temperature, that the office is overcrowded or dark. If you score poorly on this question you should consider doing the following:",
        suggestedActions: [
          {
            heading: "Suggested Actions:",
            actions: [
              {
                text: "Consider which elements of your working environment are causing you to be unhappy and how you might help your manager find a solution to make things better for you. For instance they might not be able to improve your journey to work, but perhaps you could have more flexible working hours.",
                children: []
              },
              {
                text: "Check if your colleagues feel similarly and work together on a plan to improve things.",
                children: []
              },
              {
                text: "If your concern is related to a health and safety issue take immediate action. Notify your manager and all relevant office holders in your organisation. Stop work if there is an imminent danger.",
                children: []
              }
            ]
          }
        ]
      },
      {
        question: "Do you feel relaxed and calm at work?",
        explanation:
          "There are many reasons why people may feel anxious/depressed coming into work. It may be something to do with their home life and/or their time at work. If you have taken the individual WorkL Workplace Happiness Survey and scored poorly for Wellbeing you will be directed to take the NHS anxiety and depression test, which provides clinical advice.",
        suggestedActions: [
          {
            heading: "Suggested Actions:",
            actions: [
              {
                text: "If you are based in the UK and concerned at any time about  your mental wellbeing, visit @LINK@www.nhs.uk/conditions/stress-anxiety-depression/@LINK@",
                children: [],
                placeholder: {
                  key: "@LINK@",
                  url: "https://www.nhs.uk/conditions/stress-anxiety-depression/"
                }
              },
              {
                text: "Try to share your concerns with your manager.",
                children: []
              },
              {
                text: "If it’s your job which is causing concern, discuss what support, training, resources, time etc you might need to feel better about your job/work. The Workplace Happiness Survey will help you identify where your concerns lie. ",
                children: []
              },
              {
                text: "If you conclude you are in the wrong job or role, use the free WorkL Career Developer to find which career and role might suit you best. There is a career path test and a personality test.",
                children: []
              },
              {
                text: "If it is your relationship with your line manager that is the concern but you like your job and the organisation, speak to a member of the HR team or perhaps get a mentor. You can connect with one free of charge by using the WorkL Mentor Matcher.",
                children: []
              }
            ]
          }
        ]
      }
    ],
    supportiveMaterials: [
      {
        type: "Wellbeing helpline",
        title: "0808 801 0808",
        url: "",
        author: "Retail Trust"
      },
      {
        type: "Article",
        title: "11 small, but significant, ways you can look after your wellbeing",
        url: "https://www.retailtrust.org.uk/summer-wellbeing/11-small-but-significant-ways-you-can-look-after-your-wellbeing/1146.article?rwp_campaign=HA2&rwp_medium=HappinessAssessment",
        author: "Retail Trust"
      },
      {
        type: "Article",
        title: "The seven signs of burnout, and how to restore balance",
        url: "https://www.retailtrust.org.uk/work-life-balance/the-seven-signs-of-burnout-and-five-ways-to-restore-balance/1129.article?rwp_campaign=HA2&rwp_medium=HappinessAssessment",
        author: "Retail Trust"
      },
      {
        type: "Article",
        title: "Five ways to get a better night's sleep",
        url: "https://www.retailtrust.org.uk/sleeping-well/five-sleep-tips-from-the-sleep-geek/1133.article?rwp_campaign=HA2&rwp_medium=HappinessAssessment",
        author: "Retail Trust"
      },
      {
        type: "Article",
        title: "Eat yourself happy: ten ways food (and drink) can boost your mental health",
        url: "https://www.retailtrust.org.uk/winter-wellness/eat-yourself-happy-ten-ways-food-and-drink-can-boost-your-mental-health/967.article?rwp_campaign=HA2&rwp_medium=HappinessAssessment",
        author: "Retail Trust"
      },
      {
        type: "Article",
        title: "Six of the best excuse-busting exercise hacks",
        url: "https://www.retailtrust.org.uk/winter-wellness/six-of-the-best-excuse-busting-exercise-hacks/966.article?rwp_campaign=HA2&rwp_medium=HappinessAssessment",
        author: "Retail Trust"
      },
      {
        type: "Article",
        title: "Five ways to lower stress in under 5 minutes",
        url: "https://www.retailtrust.org.uk/work-life-balance/five-ways-to-lower-stress-in-under-five-minutes/1171.article?rwp_campaign=HA2&rwp_medium=HappinessAssessment",
        author: "Retail Trust"
      },
      {
        type: "Article",
        title: "The 12 ways of achieving financial wellness",
        url: "https://www.retailtrust.org.uk/financial-wellness/the-twelve-ways-of-achieving-financial-wellness/985.article?rwp_campaign=HA2&rwp_medium=HappinessAssessment",
        author: "Retail Trust"
      },
      {
        type: "Article",
        title: "How to pay your next bill",
        url: "https://www.retailtrust.org.uk/financial-wellness/how-to-pay-your-next-bill/983.article?rwp_campaign=HA2&rwp_medium=HappinessAssessment",
        author: "Retail Trust"
      },
      {
        type: "Article",
        title: "Is your money mindset pushing you into debt?",
        url: "https://www.retailtrust.org.uk/financial-wellness/is-your-money-mindset-pushing-you-into-debt/1017.article?rwp_campaign=HA2&rwp_medium=HappinessAssessment",
        author: "Retail Trust"
      }
    ],
    furtherReading: []
  },

  {
    section: "instilling_pride",
    heading: "Instilling Pride",
    icon: "star",
    paragraph:
      "You gain pride from the products and services your organisations provide, the attitudes and behaviour of your colleagues and managers. Find out new ways to think about this using our action plan.",
    modalInfo: [
      {
        question: "Do you feel proud to work for your organisation?",
        explanation:
          "It’s great if you feel proud when you tell someone where you work. Academic research suggests that where people feel a sense of purpose, they contribute more to the organisation’s success and are happier. People working for charities usually score highly on this dimension. If you score poorly on this question you should consider doing the following:",
        suggestedActions: [
          {
            heading: "Suggested Actions:",
            actions: [
              {
                text: "Do you understand how you contribute to the organisation’s overarching strategic charitable objectives, and the impact of the organisation’s work on producers? If not, how could you get that information?",
                children: []
              },
              // {
              //   text:
              //     "If the organisation does not have a clearly articulated social purpose what might it be? For example if you make mattresses you’re helping people sleep better so that they’re happier. If you grow apples you are preserving and protecting the countryside and helping people eat healthily.",
              //   children: []
              // },
              {
                text: "Do you or your colleagues support a local charity by perhaps donating time or running sponsored events?",
                children: []
              }
            ]
          }
        ]
      },
      {
        question: "How likely are you to recommend friends and family to work at your organisation?",
        explanation:
          "One of the surest ways to test if people are happy where they work is to ask if they would recommend where they work to their friends and family. If you wouldn’t recommend your workplace, you need to think if that can be fixed or if you are in the wrong organisation or job. Take a look at the Organisation Satisfaction Matrix. Anarchists are unlikely to talk positively about where they work. For those that fall into the Anarchists part of the chart, it’s likely to affect your wellbeing in the long term, as well as being bad for the organisation.",
        suggestedActions: [
          {
            heading: "Suggested Actions:",
            actions: [
              {
                text: "Consider why you have scored poorly on this point, referring back to your scores on other questions.",
                children: []
              },
              {
                text: "Do you feel you can address the points raised?",
                children: []
              },
              {
                text: "If you can’t, and your score in the area suggests you are unhappy, consider how you might move to other roles where you work or another organisations where you will be happier. You can use the WorkL Career Developer to test whether you're in the right career and your personality profile matches well with what you are doing, or if you want to consider other jobs in other companies you can visit the @LINK@WorkL Jobs Marketplace@LINK@.",
                children: [],
                placeholder: {
                  key: "@LINK@",
                  url: "https://app.workl.com/find-work/jobs"
                }
              }
            ]
          }
        ]
      },
      {
        question: "Do you feel you are doing something worthwhile?",
        explanation:
          "Everyone wants to feel that they are making a contribution to an organisation’s success and society. There’s a wonderful story about President J F Kennedy visiting NASA to see the space rockets. In the line to meet him was a cleaner. When President Kennedy asked him what his job was, he said “putting a man on the moon!” Teachers and nurses all tend to score highly on this question, but so can others if they understand the contribution they are making.",
        suggestedActions: [
          {
            heading: "Suggested Actions:",
            actions: [
              {
                text: "Do you appreciate the importance of your role to the success of the organisation?",
                children: []
              },
              {
                text: "Do you appreciate the contribution your organisation is making to society?",
                children: []
              }
            ]
          }
        ]
      }
    ],
    supportiveMaterials: [
      {
        type: "Article",
        title: "Why purpose boosts health and wellbeing – and how to find yours",
        url: "https://www.retailtrust.org.uk/bring-your-best-self-to-work/how-purpose-boosts-wellbeing-and-eight-ways-to-find-yours/1188.article?rwp_campaign=HA2&rwp_medium=HappinessAssessment",
        author: "Retail Trust"
      },
      {
        type: "Article",
        title: "How to bring what matters to you into your workplace",
        url: "https://www.retailtrust.org.uk/bring-your-best-self-to-work/how-to-bring-what-matters-to-you-into-your-workplace/1187.article?rwp_campaign=HA2&rwp_medium=HappinessAssessment",
        author: "Retail Trust"
      }
    ],
    furtherReading: []
  },

  {
    section: "job_satisfaction",
    heading: "Job Satisfaction",
    icon: "thumbsUp",
    paragraph:
      "Do you go home at the end of the day or the end of the week feeling that you have made a positive contribution to your organisation and its customers? If you are struggling in this area, our action plan may help.",
    modalInfo: [
      {
        question: "Do you feel you are being developed?",
        explanation:
          "For many people it is important that they feel they are being developed and as a consequence can increase their earning potential. Even mature employees want to keep learning, and it’s vital for new employees if the organisation is to achieve its potential. Employees want to feel that their boss is developing them rather than just helping make their boss look good. If you score poorly on this question you should consider the following:",
        suggestedActions: [
          {
            heading: "Suggested Actions:",
            actions: [
              {
                text: "Do you spend time considering where you might improve your performance? You could keep a career diary, recording your areas for development each day. What went well, what went less well and what will you do differently in the future?",
                children: []
              },
              {
                text: "Do you ask your manager and colleagues how you might improve your performance and learn more on the job?",
                children: []
              },
              {
                text: "Have you used the @LINK@WorkL Business Library@LINK@ to find more resources and information to help you develop?",
                children: [],
                placeholder: {
                  key: "@LINK@",
                  url: "https://app.workl.com/business-library"
                }
              },
              {
                text: "Have you used WorkL to search for a mentor or coach?",
                children: []
              },
              {
                text: "Do you have a regular review of performance with your manager to discuss your training and development?",
                children: []
              }
            ]
          }
        ]
      },
      {
        question: "Do you have a good relationship with your line manager?",
        explanation:
          "The most critical relationship in the workplace is between an individual and their boss. 83% of people leave their jobs because of the poor relationship they have with their line manager, even if they work for a great company. There can be many reasons for this, but if you have a low score in this area you may like to consider the following:",
        suggestedActions: [
          {
            heading: "Suggested Actions:",
            actions: [
              {
                text: "Take an interest in your manager - finding out about their personal life and their hopes and ambitions for work and their wellbeing. If you are interested in them there’s a high chance they’ll be interested in you.",
                children: []
              },
              {
                text: "Recognise that we are all different as we have different skills, experiences and backgrounds.",
                children: []
              },
              {
                text: "Positively encourage your manager to help you improve your performance. Don’t be defensive.",
                children: []
              },
              {
                text: "Try to be positive. A negative outlook and approach will affect you as well as those around you.",
                children: []
              },
              {
                text: "Be interested and engaged in your work.",
                children: []
              },
              {
                text: "If you do not fit the role or organisation, take the positive steps of looking for a new role or organisation.",
                children: []
              }
            ]
          }
        ]
      },
      {
        question: "Are you treated with respect?",
        explanation:
          "It is hard to be satisfied at work if you do not feel respected by your manager and/or colleagues. If you have a low score for this question you can consider:",
        suggestedActions: [
          {
            heading: "Suggested Actions:",
            actions: [
              {
                text: "Why do you feel you have not been treated with respect? Perhaps consider the following and see if you identify with any of these points:",
                children: [
                  "You aren’t paid fairly.",
                  "You’re not recognised when you do something well.",
                  "You don’t feel information is shared with you.",
                  "Your views aren’t heard.",
                  "You don’t feel trusted to make decisions.",
                  "Your wellbeing is cared for.",
                  "You don’t do a job which is worthwhile.",
                  "You don’t have a good relationship with your manager.",
                  "You are being taken for granted and not developed."
                ]
              },
              {
                text: "Build action plans around the topics that resonate most with you. Your HR team or a mentor can help you unpick this complex area.",
                children: []
              }
            ]
          }
        ]
      },
      {
        question: "Do you enjoy your job?",
        explanation:
          "It is possible to work for a great organisation, a great boss, have wonderful colleagues but still feel that you are in the wrong job. It is hard to see, if that is the case, how you can feel happy at work over the long term. If you score poorly on this question you should consider:",
        suggestedActions: [
          {
            heading: "Suggested Actions:",
            actions: [
              {
                text: "If you have eliminated the issues listed in the action plan above, and if it is the job itself you don’t enjoy, what would you enjoy doing?",
                children: []
              },
              {
                text: "Is that role available in your department or organisation?",
                children: []
              },
              {
                text: "What training or support is required to achieve your preferred role, and can this organisation support you with that?",
                children: []
              },
              {
                text: "Take control of your own career. There is a career path and personality profile test on WorkL to help you discover where your skills and interests best fit, as well as a @LINK@Jobs Marketplace@LINK@ to search for new roles and see which are the happiest companies to work for.",
                children: [],
                placeholder: {
                  key: "@LINK@",
                  url: "https://app.workl.com/find-work/jobs"
                }
              }
            ]
          }
        ]
      }
    ],
    supportiveMaterials: [
      {
        type: "Article",
        title: "How to request useful feedback from managers and co-workers",
        url: "https://app.workl.com/business-library/browse/articles/5dd52714778d280010af1d24",
        author: "WorkL"
      },
      {
        type: "Article",
        title: "(19 Ways) How to find your happy at work (whatever job you do)",
        url: "https://www.retailtrust.org.uk/work-life-balance/19-ways-to-find-your-happy-at-work/1172.article?rwp_campaign=HA2&rwp_medium=HappinessAssessment",
        author: "Retail Trust"
      },
      {
        type: "Article",
        title: "The new work life balance and five steps to help you achieve it",
        url: "https://www.retailtrust.org.uk/work-life-balance/five-steps-to-achieving-a-better-balance/1169.article?rwp_campaign=HA2&rwp_medium=HappinessAssessment",
        author: "Retail Trust"
      }
    ],
    furtherReading: []
  }
]

export default RetailTrustActionPlanItems
