import { Flex, H3, Paragraph, Text } from "@engaging-tech/components"
import React from "react"

import HomepageBlock from "../../ui/components/HomepageBlock"

const whatIsWorkLSvg = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/about-us/what-is-workl.svg`)

const AboutUs = () => (
  <HomepageBlock bg="primary.0">
    <Flex
      flexDirection={["column", "column", "row"]}
      width={1 / 1}
      justifyContent="center"
      alignItems="center"
    >
      <Flex flexDirection="column" width={1 / 1}>
        <Text
          fontFamily="serif"
          textAlign="flex-start"
          mb={5}
          fontSize={[7, 8]}
          fontWeight="600"
          color="#fff"
          width="auto"
        >
          About WorkL
        </Text>

        <H3 fontWeight="700" color="#fff" fontSize={[6, 7]} mb={4}>
          What is WorkL?
        </H3>

        <Paragraph color="#fff" fontSize={[4, "18px"]} fontWeight={500}>
          WorkL.com is a digital platform and app which helps individuals to have a better working life. With
          more data and insight on the happiest companies to work for than anyone else in the world, we can
          help you find the perfect job. Plus, we’ve got lots of handy tools and services to you get on in
          your career.
        </Paragraph>

        <H3 fontWeight="700" color="#fff" fontSize={[6, 7]} my={4}>
          Who is WorkL for?
        </H3>

        <Paragraph color="#fff" fontSize={[4, "18px"]} fontWeight={500}>
          WorkL is for anyone who wants to have a better working life, whether you’re looking for your first
          job, wanting to develop your career, find or become a mentor or on the search for inspiring content.
        </Paragraph>
      </Flex>
      <Flex width={["80%", "70vw", "70vw"]} maxWidth={550} m={40}>
        <img alt="What is WorkL?" src={whatIsWorkLSvg} loading="lazy" width="100%" />
      </Flex>
    </Flex>
  </HomepageBlock>
)

export default AboutUs
