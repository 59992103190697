import { connect } from "react-redux"

import List from "../components/PostList"
import { loadAll } from "../store/businessLibrary.actions"
import { getAll, getHasResults, getIsLoading } from "../store/businessLibrary.selectors"

const AllPosts = postType => {
  const mapState = state => ({
    isLoading: getIsLoading(state),
    posts: getAll(state),
    noResults: !getHasResults(state),
    type: postType
  })

  const mapDispatch = dispatch => ({
    onLoad: () => dispatch(loadAll({ postType }))
  })

  return connect(mapState, mapDispatch)(List)
}

export default AllPosts
