import { Card, Flex, H4, MobileOnly, TabletUp, Text } from "@engaging-tech/components"
import { Link, useLocation, usePaths } from "@engaging-tech/routing"
import React from "react"
import styled from "styled-components"

import CategoryChips from "./CategoryChips"

const Thumbnail = styled.img`
  max-height: 200px;
  max-width: 150px;
  width: auto;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
`

const FullWidthLink = styled(Link)`
  width: 100%;
  cursor: "pointer";
`

const BookMeta = ({ book }) => {
  return (
    <Flex flexDirection="column" pl={[3, 0]}>
      <H4 mb={1} fontSize={4} fontWeight={700}>
        {book.title && book.title}
      </H4>
      <Flex flexDirection="row" flexWrap="wrap">
        {book.authors &&
          book.authors.map((author, aIndex) => (
            <Text
              key={author.name + aIndex.toString()}
              mb={1}
              mr={2}
              fontSize={2}
              fontWeight="500"
              width="auto"
              color="dark.2"
            >
              {author.name}
            </Text>
          ))}
      </Flex>
      <Text mb={1} fontSize={2} fontWeight="500" color="dark.2">
        {book.price && `£${book.price.toFixed(2)}`}
      </Text>
    </Flex>
  )
}

const BookPreview = ({ book }) => {
  const paths = usePaths()

  return (
    <FullWidthLink
      to={paths.resourceCentre.browse.books.selectedBook.replace(":id", book.id)}
      style={{ width: "100%" }}
    >
      <Card
        mb={3}
        p={3}
        bg="light.0"
        flexDirection={["column", "row"]}
        width={1 / 1}
        MaxWidth={700}
        ml="auto"
      >
        <MobileOnly>
          <Flex width={1 / 1}>
            {book.image && <Thumbnail src={`https://gardnersmedia.engaging.works${book.image}`} />}
            <BookMeta book={book} />
          </Flex>
        </MobileOnly>
        <TabletUp>
          {book.image && <Thumbnail src={`https://gardnersmedia.engaging.works${book.image}`} />}
        </TabletUp>
        <Flex flexDirection="column" pl={[0, 3]} width={1 / 1}>
          <TabletUp>
            <BookMeta book={book} />
          </TabletUp>
          <Text color="dark.2" lineHeight="1.4" mt={2}>
            {book.shortDescription && book.shortDescription}
          </Text>
          {!!book.bicCliassifications.length && <CategoryChips classifications={book.bicCliassifications} />}
        </Flex>
      </Card>
    </FullWidthLink>
  )
}

export default BookPreview
