import { emailEW } from "./globalConstants"

export const header = [
  "PLEASE NOTE, THESE TERMS APPLY TO THE PROVISION OF OUR ‘WORKL’ SERVICES, i.e. OUR CONSUMER-FACING SERVICES OUTLINED IN MORE DETAIL AT https://workl.com. FOR OUR ‘WORKL FOR BUSINESS’ B2B TERMS AND CONDITIONS, PLEASE SEE https://workl.com/business."
]

export const terms = [
  {
    title: "THESE TERMS",
    children: [
      {
        content:
          "What these terms cover. These are the terms and conditions on which we supply our services to you. In these terms, we refer to both ‘services’ (by which we mean any of our services, including Premium services) and ‘Premium services’ (by which we specifically mean our paid-for Premium subscription service)."
      },
      {
        content:
          "Why you should read them. Please read these terms carefully before you use our services. These terms tell you who we are, how we will provide our services to you, how you and we may change or end our contract with you, what to do if there is a problem and other important information. If you think that there is a mistake in these terms, please contact us to discuss."
      }
    ]
  },
  {
    title: "INFORMATION ABOUT US AND HOW TO CONTACT US",
    children: [
      {
        content:
          "Who we are. We are Engaging Works Group Ltd, a company registered in England and Wales. Our company registration number is 07946689 and our registered office is at Mole End Shorts Green Lane, Motcombe, Shaftesbury, Dorset, England, SP7 9PA. Our registered VAT number is 225707317."
      },
      {
        content: `How to contact us. To contact us please email our customer service team at ${emailEW}.`
      },
      {
        content:
          "How we may contact you. If we have to contact you we will do so either by telephone or by writing to you at the email address or postal address you provided to us in your order."
      },
      {
        content:
          "“Writing” includes emails. When we use the words “writing” or “written” in these terms, this includes emails."
      }
    ]
  },
  {
    title: "OUR CONTRACT WITH YOU",
    children: [
      {
        content:
          "Free services. Certain of our services are free to use and/or sign up for. These include (but are not limited to) the Happiness Survey, Global Hub, Mentor Matching, Career Tests (EQ, IQ, MBTI and Careers Path) and Business Library, details of which are set out on our website. A contract will come into existence between you and us when you start using any of these services."
      },
      {
        content:
          "Premium services. For our Premium services, our acceptance of your order will take place when we email you to accept it, at which point a contract will come into existence between you and us."
      },
      {
        content:
          "If we cannot accept your request. If we are unable to accept your request, we will inform you of this and will not charge you for the services. This might be because we have identified an error in the price or description of our services, or because of unexpected limits on our resources which we could not reasonably plan for."
      }
    ]
  },
  {
    title: "YOUR RIGHTS TO MAKE CHANGES",
    content:
      "If you wish to make a change to the services you have ordered please contact us. We will let you know if the change is possible. If it is possible we will let you know about any changes to the price of the services or anything else which would be necessary as a result of your requested change and ask you to confirm whether you wish to go ahead with the change. If we cannot make the change or the consequences of making the change is unacceptable to you, you may want to end the contract (see Clause 7 below)."
  },
  {
    title: "OUR RIGHTS TO MAKE CHANGES",
    children: [
      {
        content:
          "Minor changes to the services. We may change the services to reflect changes in relevant laws and regulatory requirements, to implement minor adjustments and improvements or otherwise as required by us from time to time. These changes will not materially affect your use of the services."
      },
      {
        content:
          "More significant changes to the services or these terms. In addition, we may make more significant changes to our services, but if we do so we will notify you and you may then contact us to end the contract before the changes take effect and receive a refund for any services paid for but not received."
      }
    ]
  },
  {
    title: "PROVIDING THE SERVICES",
    children: [
      {
        content:
          "We will supply the relevant services to you until either the services are completed or the subscription expires (if applicable) or you end the contract as described in Clause 8 or we end the contract by written notice to you as described in Clause 10."
      },
      {
        content:
          "We are not responsible for events outside our control. If our supply of the services is delayed by an event outside our control then we will contact you as soon as possible to let you know and we will take steps to minimise the effect of the delay in providing the services. Provided we do this we will not be liable for delays caused by such an event, but if there is a risk of substantial delay you may contact us to end the contract and receive a refund for any Premium services you have paid for but not received."
      },
      {
        content:
          "Reasons we may suspend the supply of services to you. We may have to suspend the supply of certain services to:",
        children: [
          "deal with technical problems or make minor technical changes; or",
          "update the services to reflect changes in relevant laws and regulatory requirements."
        ]
      },
      {
        content:
          "Your rights if we suspend the supply of services. We will contact you in advance to tell you we will be suspending supply of the services, unless the problem is urgent or an emergency. If we have to suspend the Premium services, we will adjust the price so that you do not pay for services while they are suspended. You may contact us to end the contract for a service if we suspend it, or tell you we are going to suspend it, in each case for a period of more than one month and we will refund any sums you have paid in advance for the Premium services in respect of the period after you end the contract."
      },
      {
        content:
          "We may also suspend supply of the Premium services if you do not pay. If you do not pay us for the Premium services when you are supposed to (see Clause 12.4) and you still do not make payment within 7 days of us reminding you that payment is due, we may suspend the supply of the services until you have paid us the outstanding amounts. We will contact you to tell you we are suspending supply of the services. We will not suspend the services where you dispute the unpaid invoice. We will not charge you for the services during the period for which they are suspended. As well as suspending the services we can also charge you interest on your overdue payments (see Clause 12.5)."
      }
    ]
  },
  {
    title: "FREE TRIAL",
    children: [
      {
        content:
          "Your subscription for Premium services may start with a free trial, the duration of which will be specified during the sign-up process. The free trial is intended to allow new users the opportunity to try the Premium services."
      },
      {
        content:
          "Free trial eligibility is determined by us in our sole discretion, and we may limit eligibility or the duration of the free trial at any time in order to prevent abuse of the free trial. We reserve the right to revoke the free trial and suspend provision of the services to you in the event that we determine that you are not eligible."
      },
      {
        content:
          "You may cancel at any time during the trial period. At the end of the free trial, we will charge you for the first billing cycle (as set out in Clause 12.4) unless you cancel prior to the end of the trial period."
      }
    ]
  },
  {
    title: "YOUR RIGHTS TO END THE CONTRACT",
    children: [
      {
        content:
          "Your right to cancel. You have the right to cancel any services (including Premium services) within [14 days] from the day on which you enter into the contract or, for Premium services within [14 days] after the end of the free trial. If any Premium services have already been provided between the end of the free trial and the date of cancellation, you will be required to pay for the value of the service that has been provided to you from the end of the free trial up to the point you cancel. [Note: 14 days is the minimum cancellation period that consumers must be given, but you can extend this beyond 14 days if you wish]"
      },
      {
        content:
          "Ending the contract because of something we have done or are going to do. If you are ending a contract for a reason set out below the contract will end immediately and we will refund you in full for any services which have not been provided. The reasons are:",
        children: [
          "we have told you about an upcoming change to the services or these terms which you do not agree to (see Clause 5.2)",
          "we have told you about an error in the price or description of the services you have signed up to and you do not wish to proceed;",
          "there is a risk that supply of the services may be significantly delayed or affected because of events outside our control;",
          "we have suspended supply of the services for technical reasons, or notify you that we are going to suspend them for technical reasons, in each case for a period of more than one month; or",
          "you have a legal right to end the contract because of something we have done wrong."
        ]
      },
      {
        content:
          "Ending the contract where we are not at fault and there is no right to change your mind. Even if we are not at fault and you do not have a right to change your mind (see Clause 8.1), you can still end the contract before it is completed, but you may have to pay us compensation. The contract will end immediately and we will refund any sums paid by you for services not provided but we may deduct from that refund (or, if you have not made an advance payment, charge you) reasonable compensation for the net costs we will incur as a result of your ending the contract."
      }
    ]
  },
  {
    title: "HOW TO END THE CONTRACT WITH US (INCLUDING IF YOU HAVE CHANGED YOUR MIND)",
    children: [
      {
        content:
          "Tell us you want to end the contract. To end the contract with us, please let us know by doing one of the following:",
        children: [
          `Phone or email. Email us at ${emailEW}. Please provide your name, details of the order and, where available, your phone number and email address.`,
          "Online. Contact us via our website: https://workl.com.",
          "By post. Post us your request to Coppergate House, 10 Whites Row, London, E1 7NF. Or simply write to us at that address, including details of what you bought, when you ordered or received it and your name and email address."
        ]
      },
      {
        content:
          "How we will refund you. Where we pay you any refund in accordance with these terms, we may deduct from any refund an amount for the supply of the services for the period for which they were supplied, ending with the time when you told us you wish to cancel. The amount will be in proportion to what has been supplied, in comparison with the full coverage of the contract. We will make any refunds due to you as soon as possible."
      }
    ]
  },
  {
    title: "OUR RIGHTS TO END THE CONTRACT",
    children: [
      {
        content:
          "We may end the contract if you break it. We may end the contract for a service at any time by writing to you if:",
        children: [
          "you do not make any payment to us when it is due and you still do not make payment within 7 days of us reminding you that payment is due; or",
          "you are in breach of these terms in any other way."
        ]
      },
      {
        content:
          "You must compensate us if you break the contract. If we end the contract in the situations set out in Clause 10.1 we will refund any money you have paid in advance for Premium services we have not provided but we may deduct or charge you reasonable compensation for the net costs we will incur as a result of your breaking the contract."
      },
      {
        content:
          "We may withdraw the services. We may write to you to let you know that we are going to stop providing any of the services or the Premium services. We will let you know as soon as possible in advance of our stopping the supply of such services and will refund any sums you have paid in advance for services which you have paid for but will not be provided."
      }
    ]
  },
  {
    title: "IF THERE IS A PROBLEM WITH THE SERVICES",
    content: `How to tell us about problems. If you have any questions or complaints about the services, please contact us. You can email us at ${emailEW} or write to us at our head office in London.`
  },
  {
    title: "PRICE AND PAYMENT",
    children: [
      {
        content:
          "Where to find the price for the Premium services. The price of the Premium services (which includes VAT) will be the price indicated on our website from time to time. We use our best efforts to ensure that the price of the services advised to you is correct. However, please see Clause 12.3 for what happens if we discover an error in the price of the services you order."
      },
      {
        content:
          "We will pass on changes in the rate of VAT. If the rate of VAT changes between your order date and the date we supply the services, we will adjust the rate of VAT that you pay, unless you have already paid for the services in full before the change in the rate of VAT takes effect."
      },
      {
        content:
          "What happens if we got the price wrong. It is always possible that, despite our best efforts, we display the wrong price for the services. We will normally check prices before accepting your order so that, where the services’ correct price at your order date is less than our stated price at your order date, we will charge the lower amount. If the correct price at your order date is higher than the price stated to you, we will contact you for your instructions before we accept your order."
      },
      {
        content:
          "When you must pay and how you must pay. All payments are to be made via secure card payment on https://workl.com through which the free trial is available after signing up to the service."
      },
      {
        content:
          "We can charge interest if you pay late. If you do not make any payment to us by the due date we may charge interest to you on the overdue amount at the rate of 4% a year above the base lending rate of the Bank of England from time to time. This interest shall accrue on a daily basis from the due date until the date of actual payment of the overdue amount, whether before or after judgment. You must pay us interest together with any overdue amount."
      }
    ]
  },
  {
    title: "OUR RESPONSIBILITY FOR LOSS OR DAMAGE SUFFERED BY YOU",
    children: [
      {
        content:
          "We are responsible to you for foreseeable loss and damage caused by us. If we fail to comply with these terms, we are responsible for loss or damage you suffer that is a foreseeable result of our breaking this contract or our failing to use reasonable care and skill, but we are not responsible for any loss or damage that is not foreseeable. Loss or damage is foreseeable if either it is obvious that it will happen or if, at the time the contract was made, both we and you knew it might happen, for example, if you discussed it with us during the sales process."
      },
      {
        content:
          "We do not exclude or limit in any way our liability to you where it would be unlawful to do so. This includes liability for death or personal injury caused by our negligence or the negligence of our employees, agents or subcontractors; for fraud or fraudulent misrepresentation; for breach of your legal rights in relation to the services or any other liability which it would be unlawful for us to exclude or limit."
      },
      {
        content:
          "If any digital content which we have supplied damages a device or digital content belonging to you and this is caused by our failure to use reasonable care and skill we will either repair the damage or pay you compensation. However, we will not be liable for damage which you could have avoided by following our advice to apply an update offered to you free of charge or for damage which was caused by you failing to correctly follow installation instructions or to have in place the minimum system requirements advised by us."
      },
      {
        content:
          "We are not liable for business losses. We only supply the services (including the Premium services) for domestic and private use. If you use these services for any commercial, business or resale purpose we will have no liability to you for any loss of profit, loss of business, business interruption, or loss of business opportunity."
      }
    ]
  },
  {
    title: "HOW WE MAY USE YOUR PERSONAL INFORMATION",
    content: "We will only use your personal information as set out in our privacy policy."
  },
  {
    title: "OTHER IMPORTANT TERMS",
    children: [
      {
        content:
          "We may transfer this agreement to someone else. We may transfer our rights and obligations under these terms to another organisation. We will contact you to let you know if we plan to do this. If you are unhappy with the transfer you may contact us to end the contract within 14 days of us telling you about it and we will refund you any payments you have made in advance for services not provided."
      },
      {
        content:
          "You need our consent to transfer your rights to someone else. You may only transfer your rights or your obligations under these terms to another person if we agree to this in writing."
      },
      {
        content:
          "Nobody else has any rights under this contract (except someone you pass your guarantee on to). This contract is between you and us. No other person shall have any rights to enforce any of its terms."
      },
      {
        content:
          "If a court finds part of this contract illegal, the rest will continue in force. Each of the paragraphs of these terms operates separately. If any court or relevant authority decides that any of them are unlawful, the remaining paragraphs will remain in full force and effect."
      },
      {
        content:
          "Even if we delay in enforcing this contract, we can still enforce it later. If we do not insist immediately that you do anything you are required to do under these terms, or if we delay in taking steps against you in respect of your breaking this contract, that will not mean that you do not have to do those things and it will not prevent us from taking steps against you at a later date. For example, if you miss a payment and we do not chase you but we continue to provide the services, we can still require you to make the payment at a later date."
      },
      {
        content:
          "Which laws apply to this contract and where you may bring legal proceedings. These terms are governed by English law and you can bring legal proceedings in respect of the services in the English courts. If you live in Scotland you can bring legal proceedings in respect of the services in either the Scottish or the English courts. If you live in Northern Ireland you can bring legal proceedings in respect of the services in either the Northern Irish or the English courts."
      }
    ]
  }
]
