import { Auth, Hub } from "aws-amplify"

export default class AuthEventMonitorService {
  constructor({ onSignIn, onSignOut, onTokenRefresh, onAuthenticated }) {
    this.onSignIn = onSignIn
    this.onSignOut = onSignOut
    this.onTokenRefresh = onTokenRefresh
    this.onAuthenticated = onAuthenticated
  }

  // Start listening for authentication events
  start() {
    Hub.listen("auth", this.handleAuthEvent.bind(this))
  }

  // Centralized event handler for all auth-related events
  async handleAuthEvent({ payload: { event } }) {
    switch (event) {
      case "signIn":
        await this.handleSignIn()
        break
      case "signOut":
        this.handleSignOut()
        break
      case "tokenRefresh":
        await this.handleTokenRefresh()
        break
      case "signUp":
      case "autoSignIn":
        await this.handleAuthenticated()
        break
      default:
        break
    }
  }

  // Handle actions after a successful sign-in
  async handleSignIn() {
    try {
      const currentSession = await Auth.currentSession()
      if (this.onSignIn) this.onSignIn(currentSession)
    } catch (error) {
      console.error("Error handling sign-in event:", error)
    }
  }

  // Handle sign-out events
  handleSignOut() {
    try {
      if (this.onSignOut) this.onSignOut()
    } catch (error) {
      console.error("Error handling sign-out event:", error)
    }
  }

  // Handle token refresh events
  async handleTokenRefresh() {
    try {
      const currentSession = await Auth.currentSession()
      if (this.onTokenRefresh) this.onTokenRefresh(currentSession)
    } catch (error) {
      console.error("Error handling token refresh event:", error)
    }
  }

  // Handle when a user is authenticated (e.g., sign-up flow)
  async handleAuthenticated() {
    try {
      const currentSession = await Auth.currentSession()
      if (this.onAuthenticated) this.onAuthenticated(currentSession)
    } catch (error) {
      console.error("Error handling authenticated event:", error)
    }
  }
}
