import React from "react"

import DiscoverView from "../../features/businessLibrary/views/Discover"
import PageLayout from "../../layouts/PageLayout"

const Discover = () => (
  <PageLayout>
    <DiscoverView />
  </PageLayout>
)

export default Discover
