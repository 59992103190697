import { useLocation, useRouter } from "@engaging-tech/routing"
import React, { useEffect } from "react"

import AllPosts from "../../../features/businessLibrary/containers/AllPosts"
import SideBar from "../../../features/businessLibrary/containers/SideBar"
import TopBar from "../../../features/businessLibrary/containers/TopBar"
import { libraryPostTypes } from "../../../features/userGeneratedContent/lib/postTypes"
import BrowseLibraryLayout from "../../../layouts/BrowseLibraryLayout"

const AllPodcasts = AllPosts(libraryPostTypes.podcasts)

const Podcasts = () => {
  // Workaround for right navigation, as this feature is refactored in new build.
  const { pathname } = useLocation()
  const router = useRouter()
  useEffect(() => {
    const hasRightPath = pathname.split("/").includes("podcasts")

    if (!hasRightPath) router.navigate(`${pathname}/podcasts`, { replace: true })
  }, [])

  return (
    <BrowseLibraryLayout
      sideColumn={SideBar(libraryPostTypes.podcasts)}
      topRow={TopBar(libraryPostTypes.podcasts)}
    >
      <AllPodcasts />
    </BrowseLibraryLayout>
  )
}

export default Podcasts
