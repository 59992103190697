// eslint-disable-next-line import/prefer-default-export
export const getLabelData = theScore => {
  const colorsMatrix = [
    {
      color: "#DD0000",
      text: ["happiness_data_highlight.label_data.required", "Improvement Required"],
      icon: "error"
    },
    {
      color: "#FF0000",
      text: ["happiness_data_highlight.label_data.recommended", "Improvement Recommended"],
      icon: "error"
    },
    {
      color: "#FF9800",
      text: ["happiness_data_highlight.label_data.advised", "Improvement Advised"],
      icon: "error"
    },
    {
      color: "#50C878",
      text: ["happiness_data_highlight.label_data.good", "Good Score"],
      icon: "check_circle"
    },
    {
      color: "#39B086",
      text: ["happiness_data_highlight.label_data.excellent", "Excellent"],
      icon: "check_circle"
    }
  ]

  if (theScore >= 80) return colorsMatrix[4]
  if (theScore >= 70) return colorsMatrix[3]
  if (theScore >= 60) return colorsMatrix[2]
  if (theScore >= 30) return colorsMatrix[1]
  return colorsMatrix[0]
}

export function clearCognitoStorage(storage = localStorage) {
  let idx = storage.length

  const prefix = "CognitoIdentityServiceProvider"
  while (idx) {
    idx -= 1
    const key = storage.key(idx)
    if (key.startsWith(prefix)) {
      storage.removeItem(key)
    }
  }
}

export const createUrl = (pathname, params) => {
  const paramsString = params.toString()
  const queryString = `${paramsString.length ? "?" : ""}${paramsString}`

  return `${pathname}${queryString}`
}
