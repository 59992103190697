import { Card, Flex, H4, Text, UserDetails } from "@engaging-tech/components"
import React from "react"

import Thumbnail from "../../Attachments/Thumbnail"
import CategoryChips from "./CategoryChips"

const profilePlaceholder = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/No_Photo_Empty_State.svg`)

const ArticlePreview = ({ article, interactions }) => (
  <Card mb={3} p={3} bg="light.0" flexDirection={["column", "row"]}>
    {article.coverImage.source && (
      <Thumbnail width={[1 / 1, 1 / 3]} mr={[0, 3]} mb={[3, 0]} src={article.coverImage.source} />
    )}
    <Flex flexDirection="column">
      <UserDetails
        userHoldingImage={profilePlaceholder}
        user={article.user}
        time={article.publishedAt}
        interactions={interactions}
      />
      <H4 mb={1} fontSize={4} fontWeight="500">
        {article.title}
      </H4>
      <Text color="dark.2" lineHeight="1.4">
        {article.summary.slice(0, 150)}...
      </Text>
      {article.categories.length > 0 && <CategoryChips ids={article.categories} />}
    </Flex>
  </Card>
)

export default ArticlePreview
