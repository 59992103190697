import { Footer as FooterComponent } from "@engaging-tech/navigation"
import { usePaths, useRoutes } from "@engaging-tech/routing"
import { getConfig } from "@engaging-tech/ssr-config"
import React from "react"

import AppBadges from "./AppBadges"

const socialMediaLinks = [
  {
    name: "facebook",
    url: "https://www.facebook.com/myworkl/"
  },
  {
    name: "linkedin",
    url: "http://linkedin.com/company/workl"
  },
  {
    name: "twitter",
    url: "https://twitter.com/myworkl"
  },
  {
    name: "instagram",
    url: "https://www.instagram.com/workl.co/"
  }
]

const Footer = () => {
  const routes = useRoutes().map(route => ({
    ...route,
    description: route.description
  }))

  const paths = usePaths()

  const legalRoutes = routes
    .find(route => route.path === paths.legal.index)
    .children.map(route => ({
      ...route,
      description: route.description
    }))

  const footerRoutes = [
    {
      title: "Company",
      routes: [
        routes.find(route => route.path === paths.aboutUs),
        {
          linkProps: {
            external: true,
            newTab: true
          },
          description: "Contact us",
          path: `${getConfig().workL.public.index}/contact`
        }
      ]
    },
    {
      title: "Products",
      routes: [
        {
          linkProps: {
            external: true,
            newTab: true
          },
          description: "WorkL",
          path: getConfig().workL.public.index
        },
        {
          linkProps: {
            external: true,
            newTab: true
          },
          description: "WorkL For Business",
          path: getConfig().worklForBusiness.public.index
        }
      ]
    },
    {
      title: "Legal",
      routes: [
        legalRoutes.find(route => route.path === paths.legal.cookies),
        legalRoutes.find(route => route.path === paths.legal.privacyPolicy),
        legalRoutes.find(route => route.path === paths.legal.termsAndConditions)
      ]
    }
  ]

  return (
    <FooterComponent sections={footerRoutes} socialMedia={socialMediaLinks} badges={AppBadges} boldText />
  )
}

export default Footer
