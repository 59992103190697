import { connect } from "react-redux"

import List from "../components/Browse/BookList"
import { loadAllBooks, loadDiscoveryBooks } from "../store/libraryBooks.actions"
import {
  getBooksResults,
  getDiscoveryBooksTop5,
  getHasResults,
  getIsLoading
} from "../store/libraryBooks.selectors"

const mapState = state => ({
  books: getBooksResults(state),
  top5Books: getDiscoveryBooksTop5(state),
  isLoading: getIsLoading(state),
  noResults: !getHasResults(state)
})

const mapDispatch = dispatch => ({
  onLoadTop5: () => dispatch(loadDiscoveryBooks()),
  onLoad: payload => dispatch(loadAllBooks(payload))
})

const AllBooks = connect(mapState, mapDispatch)(List)

export default AllBooks
