import cookies from "js-cookie"

export default function setSessionCookies(session) {
  const idToken = session.getIdToken().getJwtToken()
  const accessToken = session.getAccessToken().getJwtToken()
  const refreshToken = session.getRefreshToken().getToken()
  const expiresAt = session.getIdToken().getExpiration() * 1000

  cookies.set("idToken", idToken, { expires: new Date(expiresAt) })
  cookies.set("accessToken", accessToken, { expires: new Date(expiresAt) })
  cookies.set("refreshToken", refreshToken, { expires: new Date(expiresAt) })
}

export function clearSessionCookies() {
  cookies.remove("idToken")
  cookies.remove("accessToken")
  cookies.remove("refreshToken")
}
