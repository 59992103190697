import { Button, Card, Flex, Text } from "@engaging-tech/components"
import { Link, useParams, usePaths } from "@engaging-tech/routing"
import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

import SelectShaped from "../../ui/components/SelectShaped"
import { load } from "../store/survey.actions"
import { getBranding, getLanguages } from "../store/survey.selectors"
import SurveyLogo from "./surveyLogo"

const capitalize = str =>
  str
    .split(" ")
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ")

const CommonContent = ({ languages, i18n, handleLanguageChange, t, linkPath }) => (
  <>
    <Text py={3} px={[3, 4]} font="Centra">
      {t("welcome.intro4", "")}
    </Text>

    <Flex width="100%" justifyContent="center">
      <SelectShaped
        borderRadius={0}
        bg="light.0"
        width="90%"
        id="language"
        defaultValue=""
        value={i18n.language}
        onChange={handleLanguageChange}
      >
        {languages.map(lang => (
          <option key={lang.code} value={lang.code}>
            {lang.icon} {capitalize(lang.nativeName)}
          </option>
        ))}
      </SelectShaped>
    </Flex>

    <Flex flexDirection="row-reverse" justifyContent="space-between" px={[3, 4]} py={3}>
      <Link to={linkPath}>
        <Button variant="primary.0">{t("welcome.start", "")}</Button>
      </Link>
    </Flex>
  </>
)

const WelcomeContent = ({ isIsolated }) => {
  const history = useHistory()
  const paths = usePaths()
  const { id } = useParams()

  const { t, i18n } = useTranslation(["organisation_survey"])

  const dispatch = useDispatch()

  const languages = useSelector(getLanguages)
  const brand = useSelector(getBranding)

  const handleLanguageChange = async e => {
    const lang = e.target.value
    await i18n.changeLanguage(lang)

    dispatch(load({ id, lang }))
  }

  const linkPath = history.location.pathname.includes("/in-app-view")
    ? paths.inAppViewOrganisationSurvey.start.replace(":id", id)
    : paths.survey.questions.replace(":id", id)

  if (isIsolated) {
    return (
      <Card bg="light.0" elevation={8} style={{ overflow: "hidden" }}>
        <Flex
          flexDirection="column"
          backgroundColor={brand?.colour || "white"}
          justifyContent="center"
          alignItems="center"
        >
          <img
            src={brand.logo.url}
            alt=""
            height="100"
            style={
              brand.logo.isFallback
                ? {
                    padding: "20px"
                  }
                : undefined
            }
          />
        </Flex>
        <CommonContent
          languages={languages}
          i18n={i18n}
          handleLanguageChange={handleLanguageChange}
          t={t}
          linkPath={linkPath}
        />
      </Card>
    )
  }

  return (
    <Card bg="light.0" gap={4} mt={[5, 5, 6]} mb={[6, 5]} elevation={8}>
      <Flex flexDirection="column" py={[3, 4]} px={[3, 4]}>
        <SurveyLogo />
      </Flex>
      <CommonContent
        languages={languages}
        i18n={i18n}
        handleLanguageChange={handleLanguageChange}
        t={t}
        linkPath={linkPath}
      />
    </Card>
  )
}

export default WelcomeContent
