import { Link, useLocation } from "@engaging-tech/routing"
import React from "react"

import { libraryPostTypes } from "../../../lib/postTypes"
import ArticlePreview from "./ArticlePreview"
import LecturePreview from "./LecturePreview"
import PodcastPreview from "./PodcastPreview"
import ResearchPreview from "./ResearchPreview"

const Preview = ({ post, match, ...props }) => {
  const { pathname } = useLocation()

  const getPreview = () => {
    switch (post.type) {
      case libraryPostTypes.articles:
        return <ArticlePreview article={post} {...props} />

      case libraryPostTypes.podcasts:
        return <PodcastPreview podcast={post} {...props} />

      case libraryPostTypes.research:
        return <ResearchPreview research={post} {...props} />

      case libraryPostTypes.lectures:
        return <LecturePreview lecture={post} {...props} />

      default:
        return null
    }
  }

  return (
    <Link style={{ width: "100%" }} to={`${pathname}/${post.id}`}>
      {getPreview()}
    </Link>
  )
}

export default Preview
