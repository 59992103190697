import { Flex, Spinner } from "@engaging-tech/components"
import React, { useEffect } from "react"

import LoadMore from "../../containers/LoadMore"
import NoResults from "../Search/NoResults"
import Preview from "./Preview"

const BookList = ({ books, top5Books = [], onLoad, onLoadTop5, isLoading, noResults }) => {
  useEffect(() => {
    onLoad({ limit: 20 })
    onLoadTop5()
  }, [])

  if (isLoading) {
    return <Spinner color="primary.0" py={3} width={1 / 1} />
  }

  return (
    <Flex flexDirection="column" width={1 / 1}>
      {noResults ? (
        <Flex flexDirection="column">
          <NoResults type="book" isPlural="true" />
          {top5Books.map(book => (
            <Preview book={book} key={`Book-${book.eanNumber}`} />
          ))}
        </Flex>
      ) : (
        <Flex flexDirection="column">
          {books.map(book => (
            <Preview book={book} key={`Book-${book.eanNumber}`} />
          ))}
          <LoadMore />
        </Flex>
      )}
    </Flex>
  )
}

export default BookList
