import { Box, Card, H4, Text, UserDetails } from "@engaging-tech/components"
import React from "react"

import CategoryChips from "./CategoryChips"

const profilePlaceholder = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/No_Photo_Empty_State.svg`)

const getPrice = research => {
  const {
    access: { price }
  } = research
  if (price > 0) return `£${price.toFixed(2)}`
  return null
}

const ResearchPreview = ({ research, interactions }) => (
  <Card mb={3} p={3} bg="light.0">
    <UserDetails
      userHoldingImage={profilePlaceholder}
      user={research.user}
      time={research.publishedAt}
      interactions={interactions}
    />
    <H4 mb={2} fontSize={4} fontWeight="500">
      {research.title}
    </H4>
    <Box mb={2}>
      <Text fontSize={4} fontWeight="500" color="dark.2">
        {getPrice(research) || "Free research"}
      </Text>
    </Box>
    {research.summary && (
      <Text color="dark.2" lineHeight="1.4">
        {research.summary}
      </Text>
    )}
    {research.categories.length > 0 && <CategoryChips ids={research.categories} />}
  </Card>
)

export default ResearchPreview
