export default [
  {
    section: "matrices_jobSatisfaction",
    icon: "shopping-bags",
    callToActionDescription: "job",
    buttonText: "job",
    path: "https://app.workl.com/develop-career"
  },
  {
    section: "matrices_organisationSatisfaction",
    icon: "boost",
    callToActionDescription: "role",
    buttonText: "role",
    path: "https://app.workl.com/find-work"
  },
  {
    section: "matrices_wellBeing",
    icon: "sunny",
    callToActionDescription: "mental-health",
    buttonText: "mental-health",
    path: "https://www.nhs.uk/mental-health/self-help/guides-tools-and-activities/depression-anxiety-self-assessment-quiz/"
  },
  {
    section: "matrices_careerDevelopment",
    icon: "school",
    callToActionDescription: "development",
    buttonText: "development",
    path: "https://app.workl.com/develop-career/your-tests"
  },
  {
    section: "matrices_inclusiveness",
    icon: "supervised_user_circle",
    callToActionDescription: "heard",
    buttonText: "heard",
    path: "https://app.workl.com/global-hub"
  },
  {
    section: "matrices_empowerment",
    icon: "accessibility",
    callToActionDescription: "career-path",
    buttonText: "career-path",
    path: "https://app.workl.com/develop-career/mentor-matcher"
  },
  {
    section: "matrices_senseOfPurpose",
    icon: "trending_up",
    callToActionDescription: "business-materials",
    buttonText: "business-materials",
    path: "https://app.workl.com/business-library"
  },
  {
    section: "matrices_lineManagerRelationship",
    icon: "conference_speaker",
    callToActionDescription: "relationship",
    buttonText: "relationship",
    path: "https://app.workl.com/develop-career/mentor-matcher"
  },
  {
    section: "matrices_workplaceEnvironment",
    icon: "wb_incandescent",
    callToActionDescription: "ranking",
    buttonText: "ranking",
    path: "https://app.workl.com/happiest-companies"
  }
]
