import { Card, Text } from "@engaging-tech/components"
import { Link, usePaths } from "@engaging-tech/routing"
import React from "react"

import OutlineButton from "../../ui/components/OutlineButton"

const DiscoverHappiestIndustries = () => {
  const paths = usePaths()

  return (
    <Card
      width={1 / 1}
      p={3}
      mb={3}
      flexDirection={["column", "row"]}
      justifyContent="space-between"
      alignContent="center"
      alignItems={["flex-start", "center"]}
    >
      <Text color="dark.2" mr={[0, 3]} fontSize={15}>
        Are you a business owner or leader looking to post vacancies?
      </Text>
      <Link newTab external to="https://workl.com/business/workl-business-services" mb="auto" mt="auto">
        <OutlineButton elevation={0} width="auto">
          Post Vacancies
        </OutlineButton>
      </Link>
    </Card>
  )
}

export default DiscoverHappiestIndustries
