import { Flex } from "@engaging-tech/components"
import React from "react"
import styled from "styled-components"

import SiteLogo from "../../ui/components/SiteLogo"
import surveyLogoContainer from "../containers/surveyLogo.container"

const Img = styled.img`
  max-width: 100%;
  max-height: 100%;
  height: auto;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: contain;
  vertical-align: middle;
  font-style: italic;
`

const SurveyLogo = ({ brand }) => (
  <Flex width={1 / 1} justifyContent="center" height="100px">
    {brand.logo ? <Img src={brand.logo.url} alt="brand logo" /> : <SiteLogo />}
  </Flex>
)

export default surveyLogoContainer(SurveyLogo)
