import { userInterfaceNotificationsStore } from "@engaging-tech/components"
import { useRouter } from "@engaging-tech/routing"
import { Auth, Hub } from "aws-amplify"
import { Formik } from "formik"
import { delay } from "lodash"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { createProfile } from "../../../yourProfile/store/yourProfile.actions"
import { signInSuccess } from "../../store/account.actions"
import { getIsAuthenticated } from "../../store/account.selectors"
import schemaForm from "./schema.form"
import SignUpFormContent from "./signUpFormContent"

const SignUpForm = ({
  isFromPremium,
  redirectTo,
  siteLogo,
  footer,
  title,
  subtitle,
  cancelRoute,
  marketingLabel,
  termsAndConditionsLabel
}) => {
  const router = useRouter()

  const [isLoading, setIsLoading] = useState(false)

  const isAuthenticated = useSelector(getIsAuthenticated)

  const dispatch = useDispatch()

  const onSubmit = async data => {
    try {
      setIsLoading(true)

      const result = await Auth.signUp({ username: data.email, password: data.password })

      if (result.userConfirmed) {
        const signInResult = await Auth.signIn({ username: data.email, password: data.password })

        if (signInResult.signInUserSession.accessToken?.payload?.sub) {
          dispatch(createProfile(data))
          delay(() => dispatch(signInSuccess(signInResult.signInUserSession.accessToken.payload.sub)), 500)
        }
      }
    } catch (error) {
      dispatch(
        userInterfaceNotificationsStore.actions.addNotification({
          message: typeof error.message === "string" ? error.message : "Unable to sign in"
        })
      )
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (isAuthenticated) {
      if (typeof redirectTo !== "string") {
        router.redirect(redirectTo.pathname, redirectTo.state)
      } else {
        router.redirect(redirectTo)
      }
    }
  }, [isAuthenticated, redirectTo, router])

  return (
    <Formik
      initialValues={{
        firstName: "",
        lastName: "",
        location: "",
        email: "",
        password: "",
        confirmPassword: "",
        allowMarketing: false,
        acceptConditions: false
      }}
      validationSchema={schemaForm}
      onSubmit={accountDetails => onSubmit({ ...accountDetails })}
    >
      {props => (
        <SignUpFormContent
          isFromPremium={isFromPremium}
          marketingLabel={marketingLabel}
          termsAndConditionsLabel={termsAndConditionsLabel}
          cancelRoute={cancelRoute}
          siteLogo={siteLogo}
          footer={footer}
          isLoading={isLoading}
          title={title}
          subtitle={subtitle}
          {...props}
        />
      )}
    </Formik>
  )
}

export default SignUpForm
